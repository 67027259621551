<template>
  <v-dialog v-model="dialog.warning_zone" width="500px">
    <v-card>
      <v-card-title class="pl-7 flex-column red">
        <p class="headline text-center white--text">{{ $lang.SESSION_TIMEOUT }}</p>
      </v-card-title>
      <v-card-text class="pt-5  ">
        <p class="fs-18 text-center ">
          You have been idle from last 15 minutes. Your Online session will expire in
        </p>
        <p class="fs-48 text-center red--text mt-3">
          {{ dialog.countDown }}
        </p>
        <p class="fs-18 text-center red--text mb-5">
           Seconds
        </p>
        <p class="fs-16 text-center ">
          Please move your Cursor or click Continue to keep working.
        </p>
      </v-card-text>


      <v-card-actions class="text-center pb-5">
        <v-spacer></v-spacer>
        <v-btn class="fw-bold" color="primary" width="100" outlined>
          {{ $lang.CONTINUE }}
        </v-btn>
        <!--        <v-spacer></v-spacer>-->
        <!--        <v-btn class="fw-bold" color="red" width="100" outlined  @click="dialog.flag=false">-->
        <!--          {{ $lang.LOGOUT }}-->
        <!--        </v-btn>-->
        <v-spacer></v-spacer>

      </v-card-actions>
    </v-card>

  </v-dialog>
</template>
<script>
export default {
  props: ['dialog'],
  data: () => ({}),
  created() {
  },
  methods: {},
}
</script>
